import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import { checkAuth } from "../../helpers/auth";
import Results from '../../components/DashboardResults';
import ReportsFiltering from "../../components/ReportsFiltering";
import axios from "axios";
import Loading from "../../components/Miscellaneous/Loading";

import moment from 'moment';
import ScrollTopLeft from "../../components/Miscellaneous/scrollTopLeft";

moment.locale('en', {
    week: {
        dow: 1 // Monday is the first day of the week.
    }
});

class RefundRate extends React.Component {
    results = React.createRef();
    filtering = React.createRef();
    state = {
        config: null,
        selectedDimensions: {
            dimension1: 'Product Group',
            dimension2: 'Product',
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        },
        additionalFilters: {
        },
        loading: true,
        columns: this.props.columns,
        data: []
    }

    componentDidMount() {
        checkAuth('media-planner');
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/refund-rate`).then(response => {
            this.setState({
                ...this.state,
                config: response.data,
                loading: false
            })
            this.loadData();
        })
    }

    loadData() {
        this.setState({
            ...this.state,
            loading: true
        })

        axios.get(process.env.SERVER_ENDPOINT + `${this.state.config.url}?${this.getDimensionParams()}&groupByTimePeriod=${this.state.groupBy}`).then(response => {
            this.setState({
                ...this.state,
                data: response.data.data,
                //columns: response.data.columns,
                loading: false
            })
        })
    }

    onSelect(selected, additionalFilters) {
        this.setState({
            ...this.state,
            selectedDimensions: selected,
            additionalFilters: additionalFilters
        }, () => {
            this.results.current.loadData();
        })
    }

    getDimensionParams() {
        let str = '';
        for (let i in this.state.selectedDimensions) {
            if (typeof (this.state.selectedDimensions[i]) !== 'undefined') {
                if (typeof (this.state.selectedDimensions[i]) === 'string') {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i]}`;
                } else {
                    str += `&${encodeURIComponent(i)}=${this.state.selectedDimensions[i].join(`&${i}=`)}`;
                }
            }
        }

        if (Object.keys(this.state.additionalFilters).length > 0) {
            for (let i in this.state.additionalFilters) {
                str += `&${encodeURIComponent(i)}=${this.state.additionalFilters[i]}`;
            }
        }
        return str;
    }

    groupBy(value) {
        let dims = Object.assign({}, this.state.selectedDimensions);
        switch (value) {
            case 'Day':
                dims.startDate = moment().add('day', -7).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
                break;

            case 'Week':
                dims.startDate = moment().add('week', -5).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
                break;

            case 'Month':
                dims.startDate = moment().add('month', -12).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
                break;

            case 'Day of Week':
                dims.startDate = moment().add('days', -30).format('YYYY-MM-DD')
                dims.endDate = moment().format('YYYY-MM-DD')
                break;
        }

        this.filtering.current.setState({
            ...this.filtering.current.state,
            selected: dims
        });

        this.setState({
            ...this.state,
            groupBy: value
        })
    }

    getColumns() {
        let cols = Object.assign([], this.state.config.columns);
        cols.unshift(this.state.selectedDimensions.dimension2);
        cols.unshift(this.state.selectedDimensions.dimension1);
        return cols;
    }

    setHiddenCols(hiddenCols) {
        let config = {...this.state.config};
        config.defaultHiddenColumns = hiddenCols;
        this.setState({config: config});
    }

    render() {
        let cols = [];
        if(this.state.config !== null) {
            cols = this.getColumns();
        }

        let groupingCols = [];
        [this.state.selectedDimensions.dimension1, 
            this.state.selectedDimensions.dimension2, 
            this.state.selectedDimensions.dimension3, 
            this.state.selectedDimensions.dimension4
        ].map(d => d ? groupingCols.push(d) : false)
        
        return (
            <Layout>
                <SEO title="Welcome" />
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        {this.state.config !== null ?
                            <div style={style.tabContainer}>
                                <div className="refundReport">
                                    {this.state.loading ?
                                        <Loading />
                                        :
                                        <>
                                            <Results
                                                autoload={true}
                                                ref={this.results}                                                columns={cols}
                                                url={process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams()}&groupByTimePeriod=${this.state.groupBy}`}
                                                exportCSVConfig={{
                                                    name: 'refund-rate',
                                                    url: process.env.SERVER_ENDPOINT + this.state.config.url + `?${this.getDimensionParams()}&groupByTimePeriod=${this.state.groupBy}`
                                                }}
                                                defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                                sort={{
                                                    column: cols[1],
                                                    asc: false
                                                }}
                                                columnsFormat={this.state.config.columnsFormat}
                                                filters={{
                                                    selected: this.state.selectedDimensions,
                                                    additionalFilters: this.state.additionalFilters
                                                }}
                                                groupingOptions={{
                                                    cols: groupingCols,
                                                    startingRow: 0
                                                }}
                                                dimensions={this.state.config.dimensions}
                                                onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }

                                            >
                                                <h3>{this.state.config.title}</h3>
                                                <ReportsFiltering
                                                    columns={cols}
                                                    defaultHiddenColumns={this.state.config.defaultHiddenColumns}
                                                    onHiddenColsChange={hiddenCols => this.setHiddenCols(hiddenCols) }
                                                    ref={this.filtering}
                                                    onSelect={(selected, filters) => this.onSelect(selected, filters)}
                                                    selected={{
                                                        dimension1: 'Product Group',
                                                        dimension2: 'Product',
                                                        startDate: this.state.selectedDimensions.startDate,
                                                        endDate: this.state.selectedDimensions.endDate
                                                    }}
                                                    type="refund-rate"
                                                    config={this.state.config}>
                                                </ReportsFiltering>
                                            </Results>
                                            <ScrollTopLeft />
                                        </>
                                    }
                                </div>
                            </div>
                            : null}
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default RefundRate;
